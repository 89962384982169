// --- GLOBAL VARIABLES --- //
var $window = $(window);
var $document = $(document);
var $header = $( '.header' );
var bp_small = 768;
var bp_medium = 992;
var class_active = 'is-active';
var class_last = 'is-last';
var class_disabled = 'is-disabled';
var class_hide = 'hide';
var $this = null;
var $containers = $( 'html,body' );


// --- COMPONENTS, PARTS --- //
$( document ).ready( function(){
	// ---  COMPONENTS --- //
	fn_components_slider();
	fn_components_ajaxLoad();
	// fn_components_gallery();
	fn_components_fullscreen_navigation();

 	// ---  PARTS --- //
	fn_parts_show_hidden();
	fn_parts_scroll_to();
	fn_parts_select_image();

});

// --- RESIZE EVENT --- //
$( window ).resize( function(){
	fn_checkNavigationTrigger();
});

// --- LOAD EVENT --- //
$( window ).bind( 'load' , function () {
	fn_parts_dots();
	fn_components_masonry();
});

var scrollLoad = true;

$(window).scroll(function () {
    if (scrollLoad && $(window).scrollTop() >= $(document).height() - $(window).height() - 300) {
        if ($(".load-more-content.js-load-more-content").length) {
            var btn = $(".load-more-content.js-load-more-content");
            if (btn.hasClass("manualClick")) {
                scrollLoad = false;
                btn.addClass("hide");
                btn.click();
            }
        }


    }
});
