//====================================================
//  Function: Slider
//====================================================
function fn_components_slider(){
	fn_classic_slider();
	fn_factory_slider();
	fn_product_slider();

	fn_components_filter_classic();
	fn_components_filter_product()
}

function fn_factory_slider(){
	if ($( '.js-slider-factory' ).length) {
		$( '.js-slider-factory' ).slick({
			infinite: true,
			dots: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			autoplay: false,
			autoplaySpeed: 3000,
			cssEase: 'linear',
			speed: 500,
			variableWidth: true,
			nextArrow: '<span class="slick-arrows factory slick-next"><i class="icon"></i></span>',
			prevArrow: '<span class="slick-arrows factory slick-prev"><i class="icon"></i></span>',
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						variableWidth: true
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						variableWidth: true
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						variableWidth: true
					}
				},
				{
					breakpoint: 460,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						variableWidth: false
					}
				},
				{
					breakpoint: 200,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						variableWidth: false
					}
				}
			]
		});
	}
}

function fn_classic_slider(){
	if ($( '.js-slider-classic' ).length) {
		$( '.js-slider-classic' ).slick({
			infinite: true,
			dots: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			autoplay: false,
			autoplaySpeed: 3000,
			cssEase: 'linear',
			speed: 500,
			variableWidth: true,
			nextArrow: '<span class="slick-arrows classic slick-next"><i class="icon"></i></span>',
			prevArrow: '<span class="slick-arrows classic slick-prev"><i class="icon"></i></span>',
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						variableWidth: true
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						variableWidth: true
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
						variableWidth: true
					}
				},
				{
					breakpoint: 460,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						variableWidth: false
					}
				},
				{
					breakpoint: 200,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						variableWidth: false
					}
				}
			]
		});
	}
}

function fn_product_slider(){
	if ($( '.js-slider-product' ).length) {
		$( '.js-slider-product' ).slick({
			infinite: true,
			dots: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			autoplay: false,
			autoplaySpeed: 3000,
			cssEase: 'linear',
			speed: 500,
			variableWidth: true,
            adaptiveHeight: true,
			nextArrow: '<span class="slick-arrows product slick-next"><i class="icon"></i></span>',
			prevArrow: '<span class="slick-arrows product slick-prev"><i class="icon"></i></span>',
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						variableWidth: true
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						variableWidth: true
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						variableWidth: true
					}
				},
				{
					breakpoint: 460,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						variableWidth: false
					}
				},
				{
					breakpoint: 200,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						variableWidth: false
					}
				}
			]
		});
	}
}

function fn_components_filter_classic(){
	var allSlidesClassic = $('.js-slider-classic .slick-slide > div > *').clone();
	var filtered = false;

	$( '.js-filter-select-classic' ).on('click', function(){

		var filtername = $(this).data( 'filter' );
		var inst = $(this);
		var sliderGroup = inst.closest( '.js-slider-group' );
		if (filtered === false) {
			if (!$(this).hasClass( class_active )){
				sliderGroup.find('.js-filter-select-classic.' + class_active + '').removeClass( class_active );
				$(this).addClass( class_active );
				var filterSlides = allSlidesClassic.filter('.js-filter-' + filtername );
				sliderGroup.find('.js-slider-classic' ).slick('unslick').empty().append( filterSlides );
				fn_classic_slider();
			} else {
				sliderGroup.find('.js-filter-select-classic.' + class_active + '').removeClass( class_active );
				var filterSlides = allSlidesClassic.filter('.js-filter-all');
				sliderGroup.find('.js-slider-classic' ).slick('unslick').empty().append( filterSlides );
				fn_classic_slider();
			}
		}
	});
}

function fn_components_filter_product(){
	var allSlidesProduct = $('.js-slider-product .slick-slide > div > *').clone();
	var filtered = false;

	$( '.js-filter-select-product' ).on('click', function(){

		var filtername = $(this).data( 'filter' );
		var inst = $(this);
		var sliderGroup = inst.closest( '.js-slider-group' );

		if (filtered === false) {
			if (!$(this).hasClass( class_active )){
				sliderGroup.find('.js-filter-select-product.' + class_active + '').removeClass( class_active );
				$(this).addClass( class_active );
				var filterSlides = allSlidesProduct.filter('.js-filter-' + filtername );
				sliderGroup.find( '.js-slider-product' ).slick('unslick').empty().append( filterSlides );
				fn_product_slider();
			} else {
				sliderGroup.find('.js-filter-select-product.' + class_active + '').removeClass( class_active );
				var filterSlides = allSlidesProduct.filter('.js-filter-all');
				sliderGroup.find( '.js-slider-product' ).slick('unslick').empty().append( filterSlides );
				fn_product_slider();
			}
		}
	});
}