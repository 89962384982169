//====================================================
//  Function: Scroll to 
//====================================================
function fn_parts_select_image(){
	if( $( '.js-select-image' ).length ){
		$( '.js-select-image' ).on( 'click' , function () {
			var selectedImage = $(this).find('img').data('imageid'),
				imgHeader = $('.js-img-header'),
				imgThumbs = $(this).closest('.js-img-thumbs');

			// Process hide/show header image
			$(imgHeader).find('.js-img-header-item').each( function() {
				if ($(this).find('img').data('imageid') === selectedImage) {
					$(this).removeClass('hide');
				} else {
					$(this).addClass('hide');
				}
			});

			// Process hide/show header image
			$(imgThumbs).find('.js-img-thumb-item').each( function() {
				if ($(this).find('img').data('imageid') === selectedImage) {
					$(this).addClass('selected');
				} else {
					$(this).removeClass('selected');
				}
			});
		});
	}
}