//====================================================
//  Function: Gallery
//====================================================

var gallery_zoom_in = "priblížiť";
var gallery_zoom_out = "oddialiť";
var gallery_next= "ďalšía";
var gallery_prev = "predchádzajúca";
var gallery_close = "zavrieť";
var gallery_fullscreen = "na celú obrazovku";
var gallery_actual_size = "pôvodna veľkosť";
var gallery_autoplay = "autoplay";
var gallery_list = "skryť/ zobraziť zoznam";

function fn_components_gallery(){
	if ($( '.js-gallery' ).length){
		$( '.js-gallery' ).each(function(){
			var inst = $(this);

			inst.lightGallery({
				selector: '.js-gallery-item',
				hash: false
				// thumbnail: false
			})

			inst.on('onAfterOpen.lg',function(event, index, fromTouch, fromThumb){
				$( '#lg-actual-size' ).attr( 'title', gallery_actual_size);
				$( '#lg-zoom-out' ).attr( 'title', gallery_zoom_out);
				$( '#lg-zoom-in' ).attr( 'title', gallery_zoom_in);
				
				$( '.lg-autoplay-button' ).attr( 'title', gallery_autoplay);
				$( '.lg-close' ).attr( 'title', gallery_close);
				$( '.lg-fullscreen' ).attr( 'title', gallery_fullscreen);
				$( '.lg-next' ).attr( 'title', gallery_next);
				$( '.lg-prev' ).attr( 'title', gallery_prev);
				$( '.lg-toogle-thumb' ).attr( 'title', gallery_list);
			});
		});

	}
}