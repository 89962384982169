//====================================================
//  Function: Show/hidden
//====================================================
function fn_parts_show_hidden(){
	if ($( '.js-active-class-toggle-trigger' ).length){
		$( '.js-active-class-toggle-trigger' ).bind( 'click' , function() {
			$(this).closest( '.js-active-block' ).toggleClass( class_active ).find( '.js-hidden-content, .js-hidden-md-content' ).toggleClass( class_active );
		});
	}

	if ($( '.js-active-class-trigger' ).length){
		$( '.js-active-class-trigger' ).bind( 'click' , function() {
			$(this).closest( '.js-active-block' ).find( '.js-hidden-content' ).addClass( class_active );
		});
	}

	if ($( '.js-remove-class-trigger' ).length){
		$( '.js-remove-class-trigger' ).bind( 'click' , function() {
			$(this).closest( '.js-remove-block' ).find( '.js-hidden-content' ).removeClass( class_active );
		});
	}

	if ($( '.js-hidden-trigger' ).length){
		$( '.js-hidden-trigger' ).bind( 'click' , function() {
			$(this).hide();
			$(this).closest( '.js-hidden-block' ).find( '.js-hidden-content' ).addClass( class_active );
		});
	}
}

