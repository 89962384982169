//====================================================
//  Initialization Masonry
//====================================================
function fn_components_masonry(){
	if ($( '.js-masonry' ).length){
		$( '.js-masonry' ).masonry({
			itemSelector: '.js-masonry-item',
			// gutter: 20,
			percentPosition: true
		});
	}
}
