/* ==========================================================================
 *  Function: Ajax load
 * ======================================================================= */
function fn_components_ajaxLoad(){
    if ($( '.js-load-more-content' ).length){
        $( '.js-load-more-content' ).bind('click', function(){
            var thisGrid = $(this).closest( '.js-load-more-block' );
            if (!$(this).hasClass("manualClick")) {
                $(this).addClass("manualClick");
            }

            // start loading items
            thisGrid.find( '.js-load-more' ).addClass( class_disabled );
            thisGrid.find( '.js-load-more .loading-post' ).addClass( class_active );

			$.ajax({
				context: $('body'),
				url: $(this).data('url'),
				type: 'GET',
				dataType: 'json',
				data: {
					page: page,
					filter: filter
				},
				success: function(data) {
					var $liveImages = $('<div>').html(data.content);
					var imgCount = $liveImages.find('img').length;

					$('img',$liveImages).on('load', function(){
						// what to do for each image that is loaded

						imgCount--;
						if (imgCount===0) {
							thisGrid.find( '.js-load-more-grid' ).append($liveImages.children());

							thisGrid.find( '.js-load-more' ).removeClass( class_disabled );
							thisGrid.find( '.js-load-more .loading-post' ).removeClass( class_active );

							var scrollLoadReturn = true;

							page += 1;
							if (page > pageMax) {
								thisGrid.find( '.js-load-more' ).addClass( class_hide );
                                scrollLoadReturn = false;
							}

							// reinit masonry
							$( '.js-masonry' ).masonry( 'reloadItems' );
							$( '.js-masonry' ).masonry( 'layout' );

							// reinit dots
							fn_parts_dots();

                            scrollLoad = scrollLoadReturn;
						}
					}).on('error', function() {
						// Error occured when loading image but there's nothing to do about it so decrease count...
						imgCount--;
					});
				},
			});
        });
    }
}

